import useLogin from '@src/hooks/useLogin';
import { ModalInstance } from '@src/lib/CustomModal/ModalContainer';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect } from 'react';
import { CloseBtn } from '../../common/closeBtn';
import { ADULT_CERTI_MODAL } from './constants';

/**
 * 기존 VerifyageModal
 * isForse17이 true 일때 보여주는 화면
 */

interface propTypes {
  token: string;
  callback?: () => void;
}

export const closeAdultCerti17Modal = () => {
  ModalInstance.getInstance().delete(ADULT_CERTI_MODAL);
};

export const openAdultCerti17Modal = (token: string, callback?: () => void) => {
  ModalInstance.getInstance().push({
    key: ADULT_CERTI_MODAL,
    className: 'verifyage-modal-con',
    shouldCloseOnOverlayClick: true,
    component: <AdultCerti17Modal callback={callback} token={token} />,
  });
};

const AdultCerti17Modal = (props: propTypes) => {
  const { callback, token } = props;
  const { updateMature } = useLogin();
  const router = useRouter();

  const onClick = useCallback((e: any) => {
    closeAdultCerti17Modal();
    updateMature({
      token,
      mature: 1,
      auth: 1,
      callback: {
        onComplete: () => {
          if (callback) {
            callback();
            return;
          }
          // 모든 페이지에서 메인으로 이동되도록 수정
          router.push('/');

          // const { path } = TopcoPageHistory.getInstance().getLastHistory();
          // if (path) {
          //   if (path.startsWith('/comic/') || path.startsWith('/content/')) {
          //     router.replace(path);
          //   }
          //   router.push(path);
          // }
        },
        onError: () => {
          closeAdultCerti17Modal();
        },
      },
    });
  }, []);

  return (
    <div className="max-w-[400px] bg-[#2c2d30] max-h-[90vh] w-[88vw] z-[100] relative">
      <div>
        <CloseBtn
          imgPath="/images/alert/close/ico_close.png"
          className="w-[35px] h-[35px] top-[4px] right-[4px] absolute"
          onClick={() => closeAdultCerti17Modal()}
        />

        <div className="tracking-0.2 py-[15px] px-0">
          <div className="py-[1rem] px-[10px] text-[#666] text-[.8rem] font-normal leading-[1.1rem] text-center">
            <img
              src="/images/alert/char_mature.png"
              className="mt-0 mx-auto mb-[10px] h-[110px]"
              alt="ault18"
            />
            <p>
              <span className="inline-block font-medium text-white align-top">
                This content is for mature audiences only.
                <br />
                <span className="inline-flex items-center">
                  Are you over the age of{' '}
                  <img
                    className="inline-block h-[22px] my-0 mr-0 ml-[2px]"
                    src="/images/alert/icon_adult_18_dark.png"
                    alt="adult"
                  />
                  ?
                </span>
              </span>
              <br />
              (may depend upon jurisdiction.)
            </p>
            <p className="text-[#f7656d] mt-[20px]">
              <span>
                {"if you are, click YES and enjoy Day Comics'"}
                <br />
                hottest series!
                <br />
                200 FREE episodes out now!
              </span>
            </p>
          </div>
        </div>
        <div className="w-full flex align-center h-[46px] leading-[45px] relative overflow-hidden text-center border-t-[1px] border-solid border-[#575960]">
          <button
            className="text-[#adadad] block w-full h-full text-[1rem] font-medium tracking-0.2 cursor-pointer"
            onClick={() => closeAdultCerti17Modal()}
          >
            NO
          </button>
          <button
            className="text-[#ff323a] block w-full h-full text-[1rem] font-medium tracking-0.2 cursor-pointer"
            onClick={onClick}
          >
            YES
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdultCerti17Modal;
