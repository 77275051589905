
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import useLogin from '@src/hooks/useLogin';
import { UserSession } from '@src/lib/user/UserSession';
import TopcoPageHistory from '@src/lib/utils/topcoPageHistory';
import { isRefererCheck } from '@src/lib/utils/utils';
import type { NextPage } from 'next';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
// import { HomeComponent } from '../src/view/components/home';
import dynamic from 'next/dynamic';
import { openAdultCerti17Modal } from '@src/view/modal/adultCertification';
import { TopcoPartner } from '@src/lib/partner';
import SeoComponent from '@src/view/common/seo/SeoCompnent';
import cn from 'clsx';
import Head from 'next/head';

const HomeComponent = dynamic<any>(
  () => import('@src/view/components/home/HomeComponent'),
  { ssr: false },
);

const Home: NextPage = (props: any) => {
  const { isReferer } = props;
  const router = useRouter();
  const { showJoinAndLoginModal } = useLogin();

  useEffect(() => {
    if (!isReferer) {
      TopcoPageHistory.getInstance().clear();
      TopcoPageHistory.getInstance().setter({
        path: router.asPath,
        scroll: window.scrollY,
        fix: '',
      });
    }
  }, [isReferer]);

  useEffect(() => {
    const { action, targetUrl, packageName, redirectUrl, fcmToken, version } =
      router.query;
    const { token } = UserSession.getUserInfo();

    if (action && action === 'login' && token.length === 0) {
      showJoinAndLoginModal(targetUrl ? `${targetUrl}` : '');
    } else if (action === 'login') {
      router.replace('/');
    } else if (token && action && action === 'mature') {
      openAdultCerti17Modal(token, () => {
        router.replace(targetUrl ? `${targetUrl}` : '/');
      });
    }
  }, [router.query]);

  return (
    <>
      <Head>
        <link rel="manifest" href="/favicon/manifest.json" />
      </Head>
      <HomeComponent />
    </>
  );
};

 async function _getServerSideProps(context: any) {
  const isReferer: boolean = isRefererCheck(context);

  await TopcoPartner.setterSSR(context);

  return {
    props: {
      isReferer,
    },
  };
}

export default Home;


// @ts-ignore
    export async function getServerSideProps(ctx) {
// @ts-ignore
        let res = _getServerSideProps(ctx)
// @ts-ignore
        if(typeof res.then === 'function') res = await res
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          ...res,
// @ts-ignore
          props: {
// @ts-ignore
            ...(res.props || {}),
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/index',
// @ts-ignore
              loaderName: 'getServerSideProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  